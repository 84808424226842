<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Criar' }} grupo</h3>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-group row">
              <div class="col-md-8">
                <label class="col-md-12">Nome:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.nome"
                    placeholder="Digite o nome..."
                />
              </div>
              <div class="col-md-8">
            <label for="Usuarios">Usuarios</label>
            <treeselect
              :multiple="true"
              :options="lista_usuarios"
              placeholder="Selecione  usuario..."
              v-model="form.usuarios"
            />
          </div>
            
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite a Descricao..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                    @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
                    class="btn btn-primary"
                    :disabled="verif"
                >
                  Salvar
                  <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        descricao: null,
        usuarios: [],
      },
      verif: false,
    };
  },
  computed: {
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios.map(
        (cat) => ({
          id: cat.id,
          label: cat.nome,
        })
      );
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.grupo.mensagem_alert
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios.map((u) => ({
        id: u.id,
        label: u.nome,
      }));
    },
  },
  created() {
    this.preenxerCampos();
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` un Grupo no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      let a = JSON.stringify(this.form.usuarios)
      this.form.usuarios = a
      await this.$store.dispatch("grupo/create_grupo", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'grupo'
      });
    },
    async update() {
      this.verif = true;
      let a = JSON.stringify(this.form.usuarios)
      this.form.usuarios = a

      await this.$store.dispatch("grupo/update_grupo", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'grupo'
      });
    },
 async   preenxerCampos() {
      await this.$store.dispatch("configUsuarios/listar_usuarios");

      if (this.lista_campos != '')
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          usuario: JSON.parse(this.lista_campos.usuarios),

          descricao: this.lista_campos.descricao,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: #8cdde2;

}
</style>